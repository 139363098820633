import React from "react";
import ProgramSearch from "app/components/Algolia/ProgramSearch";
import { useParams } from "react-router";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useResetInitialPagination } from "hooks/useResetInitialPagination";
import { CategoriesBodyWrapper } from "../CategoriesBodyWrapper";

interface ParamTypes {
  categorySlug: string;
}

export default () => {
  const { categorySlug } = useParams<ParamTypes>();
  const { resettingPagination } = useResetInitialPagination();

  if (resettingPagination) {
    return <LoaderCentered />;
  }

  return (
    <CategoriesBodyWrapper>
      <ProgramSearch
        refinements={{
          categories: categorySlug,
        }}
      />
    </CategoriesBodyWrapper>
  );
};
